<template>
	<Dialog :dialog="mailDialog" :dialog-width="dialogWidth">
		<template v-slot:title>{{ title }}</template>
		<template v-slot:body>
			<v-container class="px-10" fluid>
				<v-form ref="mail_form" lazy-validation v-on:submit.stop.prevent="submit()">
					<v-layout class="mb-4">
						<v-flex md3 class="my-auto">
							<label class="font-weight-500 font-size-16 required">Recipients</label>
						</v-flex>
						<v-flex md9>
							<v-combobox
								class="border-light-grey"
								style="border-color: rgba(0, 0, 0, 0.38)"
								chips
								:items="autocompleteEmails()"
								v-model="email.recipients"
								placeholder="Recipients"
								label="Recipients"
								multiple
								flat
								:disabled="formLoading"
								v-on:change="validateTagEmail(email.recipients)"
								hide-details
								solo
								:rules="[vrules.required(email.recipients, 'Recipients')]"
							>
								<template v-slot:append-outer>
									<v-chip
										v-on:click="emailCCShow = !emailCCShow"
										class="append-outer-chip"
										label
										:disabled="formLoading"
										:color="emailCCShow ? 'green' : ''"
										:text-color="emailCCShow ? 'white' : ''"
									>
										<span class="font-weight-500 font-size-14">CC</span>
									</v-chip>
								</template>
								<template v-slot:selection="{ attrs, item, select, selected }">
									<v-chip
										label
										v-bind="attrs"
										:input-value="selected"
										close
										@click:close="removeEmail(item, 'recipients')"
									>
										<span class="font-weight-500 font-size-14">{{ item }}</span>
									</v-chip>
								</template>
							</v-combobox>
						</v-flex>
					</v-layout>
					<v-layout v-if="emailCCShow" class="my-2">
						<v-flex md3 class="my-auto">
							<label class="font-weight-500 font-size-16">CC</label>
						</v-flex>
						<v-flex md9>
							<v-combobox
								class="border-light-grey"
								style="border-color: rgba(0, 0, 0, 0.38)"
								v-model="email.cc"
								chips
								:disabled="formLoading"
								:items="autocompleteEmails()"
								placeholder="CC"
								label="CC"
								multiple
								flat
								v-on:change="validateTagEmail(email.cc)"
								hide-details
								solo
							>
								<template v-slot:selection="{ attrs, item, select, selected }">
									<v-chip
										label
										v-bind="attrs"
										:input-value="selected"
										close
										@click:close="removeEmail(item, 'cc')"
									>
										<span class="font-weight-500 font-size-14">{{ item }}</span>
									</v-chip>
								</template>
							</v-combobox>
						</v-flex>
					</v-layout>
					<v-layout class="my-2">
						<v-flex md3 class="my-auto">
							<label class="font-weight-500 font-size-16 required">Subject</label>
						</v-flex>
						<v-flex md9>
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`email-subject`"
								v-model="email.subject"
								:class="{
									required: !email.subject,
								}"
								:rules="[vrules.required(email.subject, 'Subject')]"
							></TextInput>
						</v-flex>
					</v-layout>
					<v-layout class="my-6">
						<v-flex md12>
							<ckeditor :editor="editor" v-model="email.message" :config="editorConfig"></ckeditor>
						</v-flex>
					</v-layout>
					<v-layout class="mt-4">
						<v-flex md12>
							<table class="width-100">
								<tr>
									<td width="100%" align="left">
										<label class="font-weight-500 font-size-16 required">Approved by: </label>
									</td>
								</tr>
								<tr>
									<td width="100%" align="left" valign="center" style="position: relative">
										<!-- <div ref="admin_signature_div" class="custom-border-grey-dashed mt-2" style="width: 100%">
											<canvas ref="admin_signature"></canvas>
										</div> -->
										<div ref="signature_div" class="custom-border-grey-dashed mt-2" style="width: 100%">
											<canvas ref="cc_signature"></canvas>
										</div>
										<v-btn
											small
											v-on:click="clear_signature()"
											text
											outlined
											style="position: absolute; bottom: 10px; right: 10px"
											><v-icon>mdi-close</v-icon></v-btn
										>
									</td>
								</tr>
							</table>
						</v-flex>
					</v-layout>
				</v-form>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				tile
				v-on:click="close_dialog()"
				class="mx-2 custom-grey-border custom-bold-button"
			>
				Close
			</v-btn>

			<v-btn
				depressed
				tile
				v-on:click="submit()"
				:disabled="!forValid && validSignature"
				class="mx-2 custom-bold-button white--text"
				color="blue darken-4"
			>
				<v-icon left>mdi-send mdi-rotate-315</v-icon>Send
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import { GetEmailData } from "@/core/lib/setting.lib";
import { SendOrderEmail } from "@/core/lib/cart.lib";
import { toSafeInteger, map, findIndex } from "lodash";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import FileTemplate from "@/view/pages/partials/FileTemplate";
import SignaturePad from "signature_pad";
export default {
	props: {
		mailDialog: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeId: {
			type: Number,
			default: 0,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formLoading: false,
			emailCCShow: false,
			pageLoading: false,
			attachments: [],
			forValid: false,
			signature: null,
			editor: ClassicEditor,
			editorConfig: {
				// The configuration of the editor.
			},
			email: {
				recipients: [],
				cc: [],
				subject: null,
				message: "",
			},
		};
	},
	watch: {
		mailDialog: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.init();
					this.initSignature();
				}
			},
		},
	},
	methods: {
		clear_signature() {
			const signature_pad = this.signature;
			if (signature_pad) {
				signature_pad.clear();
			}
		},
		close_dialog() {
			this.$emit("close", true);

			this.email = {
				recipients: [],
				cc: [],
				subject: null,
				message: null,
			};
		},
		init() {
			const _this = this;
			_this.formLoading = true;
			GetEmailData(_this.type, _this.typeUuid)
				.then((data) => {
					console.log(data);
					_this.email = new Object({
						recipients: [data.member_email ? data.member_email : data.customer_email],
						cc: [],
						subject: data.email_subject,
						message: data.email_body,
						action: "send_as_email",
					});
					//_this.initSignature();
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
				});
		},
		submit() {
			const _this = this;

			if (_this.signature.isEmpty()) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: `Please do approved by signature.` }]);
				return false;
			}

			if (!_this.$refs.mail_form.validate()) {
				return false;
			}

			_this.formLoading = true;
			_this.email.attachments = _this.attachments;
			_this.email.admin_signature = _this.signature.toDataURL();

			SendOrderEmail(_this.typeUuid, _this.email)
				.then((data) => {
					console.log(data);
					_this.email = new Object({
						recipients: [data.member_email ? data.member_email : data.customer_email],
						cc: [],
						subject: data.email_subject,
						message: data.email_body,
						action: "send_as_email",
					});
					this.$store.commit(SET_MESSAGE, [{ model: true, message: `Order Mail Send Successfully.` }]);
					_this.$emit("close", true);
					//_this.initSignature();
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
				});
		},
		updateAttachment(param) {
			this.attachments = param;
		},
		validateTagEmail(emails) {
			console.log(emails);
			/* for (let i = 0; i < emails.length; i++) {
        if (!validEmail(emails[i])) {
          emails.splice(i, 1);
        }
      } */
		},
		removeEmail(email, type) {
			const emails = this.email[type];
			const index = findIndex(emails, (row) => email == row);
			if (index >= 0) {
				this.email[type].splice(index, 1);
			}
		},
		autocompleteEmails() {
			return map(this.recipients, (row) => row.email);
		},

		initSignature() {
			const _this = this;
			_this.$nextTick(() => {
				let ccanvas = _this.$refs["cc_signature"];
				let ccanvasDiv = _this.$refs["signature_div"];
				ccanvas.setAttribute("width", 500);
				ccanvasDiv.setAttribute("style", "width:" + "500px");
				_this.signature = new SignaturePad(ccanvas);
			});
		},
	},
	components: {
		Dialog,
		TextInput,
		//FileTemplate,
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 70);
		},
		validSignature() {
			return this.admin_signature ? this.admin_signature.isEmpty() : false;
		},
	},
};
</script>
<style type="text/css" scoped>
.signature-pad {
	width: 100% !important;
}
</style>
