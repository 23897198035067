<template>
	<v-autocomplete
		:id="id"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:items="items"
		:rules="rules"
		:prepend-inner-icon="prependInnerIcon"
		:append-icon="appendIcon"
		:append-outer-icon="appendOuterIcon"
		:item-text="itemText"
		:item-value="itemValue"
		:hide-details="hideDetails"
		:return-object="returnObject"
		:multiple="multiple"
		outlined
		class="mt-3 pt-0"
		:class="{ 'dense-autocomplete': dense, 'time-picker-autocomplete': timePicker }"
		:menu-props="menuProps"
		v-model="autocompleteinput"
		:placeholder="getPlaceholder()"
		v-on:change="$emit('change', autocompleteinput)"
		v-on:click:append-outer="$emit('click:append-outer', true)"
	>
		<template v-if="isCustom" v-slot:item="{ props, item }">
			<v-row>
				<v-col md="12">
					<span class="fw-600">{{ item.label }}</span>
				</v-col>
				<v-col md="12" v-if="item.description">
					<span class="text-muted">{{ item.description }}</span>
				</v-col>
			</v-row>
		</template>
		<template v-slot:no-data>
			<v-list-item>
				<v-list-item-title v-html="`No ${placeholder} Found.`"></v-list-item-title>
			</v-list-item>
		</template>
	</v-autocomplete>
</template>
<script>
export default {
	name: "autocomplete-input",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Object, Number, String, Array],
			default: null,
		},
		items: {
			type: Array,
			required: true,
			default: () => {
				return [];
			},
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		prependInnerIcon: {
			type: String,
			default: null,
		},
		appendIcon: {
			type: String,
			default: "mdi-menu-down",
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		itemText: {
			type: String,
			default: "text",
		},
		itemValue: {
			type: String,
			default: "value",
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		timePicker: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		isCustom: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(value) {
				this.autocompleteinput = value;
			},
		},
	},
	data() {
		return {
			autocompleteinput: null,
			menuProps: {},
		};
	},
	methods: {
		getPlaceholder() {
			return `Select ${this.placeholder}`;
		},
	},
	mounted() {
		this.autocompleteinput = this.value;
		if (this.dense) {
			this.menuProps.contentClass = "dense-autocomplete-list";
		}
		if (this.timePicker) {
			this.menuProps.contentClass = "time-picker-autocomplete-list";
		}
	},
};
</script>
